import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR } from '../constants';
import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';
import React from 'react';
import { Col, Container } from 'reactstrap';
import Layout from '../components/layout/layout';
import { Helmet } from 'react-helmet';
import { LetsGetStarted } from '../components/shared/lets-get-started';

import styled from 'styled-components';
import { TitleText } from '../components/shared';
import { TextBlock } from '../components/shared/text-block';
import { ImageGrid } from '../components/about/image-grid';
import { TwoRows } from '../components/shared/two-rows';

import cover from '../../static/images/about/cover.png';
import team_image from '../../static/images/people/team-horizontal.jpg';
import team_vertical from '../../static/images/people/team-vertical.jpg';

import clutch from '../../static/images/awards/clutch.svg';
import goodfirm from '../../static/images/awards/goodfirms.svg';
import disrupt2015 from '../../static/images/awards/disrupt2015.svg';
import disrupt2016 from '../../static/images/awards/disrupt2016.svg';
import unltd from '../../static/images/awards/unltd.svg';
import mtfp from '../../static/images/awards/mtfp.svg';
import nt from '../../static/images/awards/nominet-trust.svg';
import Link from '../components/shared/link';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';

const TitleContainer = styled(Container)`
    z-index: 1;
`;

const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    padding-bottom: 50px;

    &:not(:first-of-type) {
        padding-top: 50px;
    }
`;

const TeamImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);

    return (
        <Layout showShadow={true} setCanScroll={setCanScroll} canScroll={canScroll}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>equaleyes - About us</title>
            </Helmet>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                    <Background bg={cover} design={'cover'} index={1} shadow={true} />
                    <TitleContainer>
                        <TitleText>
                            Made in Maribor, <br />
                            Loved in London.
                        </TitleText>
                    </TitleContainer>
                </Fullscreen>
                <Container>
                    <TextBlock title={'About us.'}>
                        <h2>
                            We create web and mobile apps
                            <br className="d-none d-md-block" /> that stand out.
                        </h2>
                        <p>
                            Equaleyes Solutions is an award-winning software development company, located in London, UK, and Maribor,
                            Slovenia. Each month we partner with dynamic companies who have millions of active users delivering the key
                            technology services that keep them in business.
                        </p>
                        <p>Our core areas of expertise are web and mobile development & UX UI design.</p>
                        <p>
                            We are a company with startup roots, having built and sold our own digital products. So we know the drill and
                            are fully integrated into London’s and Slovenia’s startup scene. Growing over the years, from startup founders
                            to running our agency gives us the knowledge and insight to be able to help you.
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'Our core values.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h1>Loyalty.</h1>
                        <h3 className={'space'}>We will fight for you.</h3>
                        <h1>Bravery.</h1>
                        <h3 className={'space'}>We step up.</h3>
                        <h1>Brilliance.</h1>
                        <h3>We strive for it.</h3>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'The team.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h2>25 individuals passionate about the future.</h2>
                        <p>
                            Our team has come from some of the farthest reaches of the globe and our network extends into the major startup
                            regions in Europe and North America. Our team has a combined 30 years of experience in founding and running
                            their own startups. Besides that, we have clocked literally tens of thousands of hours of development for our
                            many clients around the world.
                        </p>
                        <p>
                            The development teams that will work on your projects are the same teams that have launched our internal digital
                            products that have gotten 100,000s of downloads, 5-star app store reviews, won awards, gained multiple press
                            mentions - and won a number of high profile hackathons over the years.
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <TeamImage src={team_image} className="d-none d-md-block" />
                <TeamImage src={team_vertical} className="d-block d-md-none" />
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'Leadership.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h3>
                            <Link to={'https://www.linkedin.com/in/lukatopolovec/'}>Luka Topolovec</Link>
                        </h3>
                        <h3 className={'mb-4'}>CEO</h3>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <ImageGrid
                    images={[clutch, goodfirm, disrupt2016, disrupt2015, unltd, mtfp, nt]}
                    urls={[
                        'https://clutch.co/profile/equaleyes-solutions',
                        'https://www.goodfirms.co/companies/view/4337/equaleyes-solutions-ltd',
                        'https://devpost.com/software/sensei-hub-054x7w',
                        'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners',
                        'https://unltd.org.uk/2013/05/04/tine-postuvan-equaleyes-2/',
                        undefined,
                        'http://socialtech.org.uk/projects/equaleyes/',
                    ]}
                />
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <TwoRows txtcolor={PRIMARY_TEXT_COLOR}>
                    <Col xs={12} md={6} xl={4}>
                        <h2>London.</h2>
                        <div>ldn@equaleyes.com</div>
                        <div>71-75 Shelton Street, Covent Garden, London</div>
                        <div>WC2H 9JQ United Kingdom</div>
                    </Col>
                    <Col xs={12} md={6} xl={4} className={'spaced'}>
                        <h2>Maribor.</h2>
                        <div>Vetrinjska ulica 16</div>
                        <div>2000 Maribor</div>
                        <div>Slovenia</div>
                    </Col>
                </TwoRows>
            </Wrapper>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <LetsGetStarted bg={PRIMARY_TEXT_COLOR} color={PRIMARY_TITLE_COLOR} />
            </Wrapper>
        </Layout>
    );
};
